import { Autocomplete, MenuItem, TextField, Toolbar } from "@mui/material";
import { endOfDay, startOfDay, subDays, subHours } from "date-fns";
import React, { useEffect, useState } from "react";
import { statusMap } from "../InboxPage";

import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const { afterToday } = DateRangePicker;

const MIN_WIDTH = 300;
const filterOptions = ["Range", "Today", "Yesterday", "Recent"];
const TODAY = new Date();
const TODAY_RANGE = [startOfDay(TODAY), TODAY];

/**
 * @param {Object} props
 * @param {Array<Object>} props.bills
 * @param {React.Dispatch<React.SetStateAction<never[]>>} props.setBills
 * @param {Object} props.filters
 * @param {Array<Date>} props.filters.dateRange
 * @param {"Range" | "Today" | "Yesterday" | "Recent"} props.filters.transaction
 * @param {Number | string} props.filters.status
 * @param {(key: 'dateRange' | 'transaction' | 'status', value: string | Array<Date> | null) => void} props.updateFilter
 */
const InboxHeader = ({ bills, setBills, filters, updateFilter }) => {
  const [searchVendor, setSearchVendor] = useState("All");

  const vendors = [
    "All",
    ...new Set(bills.map((b) => b["vendor_name"])),
  ].filter((vendor) => vendor?.trim());

  useEffect(() => {
    let transaction = filters.transaction;

    const dateRanges = {
      Range: null,
      Today: TODAY_RANGE,
      Yesterday: [startOfDay(subDays(TODAY, 1)), endOfDay(subDays(TODAY, 1))],
      Recent: [subHours(TODAY, 2), TODAY],
    };

    updateFilter("dateRange", dateRanges[transaction]);
  }, [filters.transaction]);

  function vendorSearch(value) {
    setSearchVendor(value);
    if (value && value !== "All") {
      setBills(requestSearch(value, bills, ["vendor_name"]));
    }
  }

  return (
    <Toolbar sx={{ gap: 2, height: 64 }}>
      <TextField
        select
        label="View Transactions"
        value={filters.transaction}
        onChange={(e) => {
          updateFilter("transaction", e.target.value);
          updateFilter("dateRange", null);
        }}
        size="small"
        sx={{ minWidth: MIN_WIDTH }}
      >
        {filterOptions.map((filter) => (
          <MenuItem key={filter} value={filter}>
            {filter}
          </MenuItem>
        ))}
      </TextField>

      {filters.transaction === "Range" && (
        <DateRangePicker
          value={filters.dateRange}
          placeholder="From&To"
          onChange={(v) =>
            updateFilter("dateRange", v ? [v[0], endOfDay(v[1])] : v)
          }
          // showOneCalendar
          shouldDisableDate={afterToday()}
        />
      )}

      <TextField
        select
        label="Status"
        value={filters.status}
        onChange={(e) => updateFilter("status", e.target.value)}
        size="small"
        sx={{ minWidth: MIN_WIDTH }}
      >
        <MenuItem value={"all"}>All</MenuItem>
        {Object.entries(statusMap).map(([status, stats], index) => (
          <MenuItem key={index} value={status}>
            {stats.name}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        options={vendors}
        size="small"
        value={searchVendor}
        onChange={(e, v) => vendorSearch(v)}
        sx={{ minWidth: MIN_WIDTH }}
        renderInput={(props) => <TextField {...props} label="Search vendor" />}
      />
    </Toolbar>
  );
};

export default InboxHeader;

function requestSearch(query, data = [], keys = []) {
  function string(str) {
    return str?.toString().toLowerCase().replace(/\s/g, "");
  }
  const filteredQuery = string(query);
  let final_keys = keys.length > 0 ? keys : Object.keys(data[0]);

  const filterData = data.filter((i) =>
    final_keys.some((key) => {
      let value = Array.isArray(i[key]) ? i[key][0] : i[key];
      value = string(value);
      return value?.includes(filteredQuery);
    })
  );

  return filterData;
}
