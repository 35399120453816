import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Add, Delete, Remove } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { NIMBLE_URL } from "../../../../helpers/variables";
import { enqueueSnackbar } from "notistack";
import { splitDetailSchema } from "../validation/schema";
import { validLineItem } from "../validation/individual";
import { NumericFormat } from "react-number-format";

export const LineItems = ({
  reviewForm,
  setReviewForm,
  invoice,
  accounts = [],
  HEADERS,
}) => {
  const {
    splitDetails = [],
    property,
    invoice_amount,
    vendor,
    contract,
  } = reviewForm;

  let contractDefaultSplitIDs = contract?.defaultSplitIDs || "";
  let vendorDefaultSplitIDs = vendor?.defaultSplitIDs || "";

  const [profitCenterList, setProfitCenterList] = useState([]);

  const SPLIT = {
    id: uuidv4(),
    AccountCode: "",
    name: "",
    Description: "",
    Amount: "",
    storeID: "",
  };

  useEffect(() => {
    getProfitCenter();
  }, [property]);

  useEffect(() => {
    if (vendor || contract) {
      generateSplitDetails();
    }
  }, [vendor, contract]);

  function generateSplitDetails() {
    let tempList;
    if (contractDefaultSplitIDs !== "") {
      tempList = contractDefaultSplitIDs.split(",");
    } else if (vendorDefaultSplitIDs !== "") {
      tempList = vendorDefaultSplitIDs.split(",");
    }

    let finalList = [];
    if (tempList) {
      accounts.forEach((i) => {
        tempList.forEach((j) => {
          if (i.id.trim().toLowerCase() === j.trim().toLowerCase()) {
            finalList.push({
              AccountCode: i["accountNumber"],
              name: i["accountName"],
              Description: "",
              Amount: "",
              storeID: "",
            });
          }
        });
      });
    } else {
      SPLIT["Amount"] = invoice_amount;
      finalList = [SPLIT];
    }
    setReviewForm((prev) => ({
      ...prev,
      splitDetails: finalList,
    }));
  }

  async function getProfitCenter() {
    if (property) {
      setProfitCenterList([]);
      let CorporationID = property.corporationID;
      let timestamp = new Date().getTime();
      try {
        let res = await fetch(
          `${NIMBLE_URL}/GetProfitCentersList?timestamp=${timestamp}`,
          {
            method: "POST",
            headers: HEADERS,
            body: JSON.stringify({ CorporationID }),
          }
        );
        let data = await res.json();
        setProfitCenterList(data);
      } catch (error) {
        enqueueSnackbar(`Error fetching vendor list: ${error}`, {
          variant: "error",
        });
      }
    }
  }

  function removeSplitDetails(id) {
    let newSplitDetails = splitDetails.filter((i) => i["id"] !== id);
    setReviewForm((prev) => ({
      ...prev,
      splitDetails: newSplitDetails,
    }));
  }

  /**
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined} e
   */
  function handleInputChange(id, e) {
    let key = e.target.name;
    let value = e.target.value;
    updateSplitDetails(key, value, id);
  }

  function updateSplitDetails(key, value, id) {
    let tempSplitDetails = [...splitDetails];
    let tempSplit = tempSplitDetails.find((i) => i["id"] === id);
    tempSplit[key] = value;

    setReviewForm((prev) => ({
      ...prev,
      splitDetails: tempSplitDetails,
    }));
  }

  const handleOnChange = (value, id) => {
    if (value) {
      const { accountNumber, accountName } = value;
      updateSplitDetails("AccountCode", accountNumber, id);
      updateSplitDetails("name", accountName, id);
    }
  };

  const getValue = (split) =>
    accounts.find(
      (i) =>
        i["accountNumber"] === split["AccountCode"] &&
        i["accountName"] === split["name"]
    ) || null;

  const GUTTER = 1;

  function getDifference() {
    const invoiceAmount = Number(invoice_amount) || 0;
    const splitTotal = splitDetails.reduce(
      (acc, { Amount }) => acc + (Number(Amount) || 0),
      0
    );
    return invoiceAmount - splitTotal;
  }

  return (
    <Paper elevation={2} sx={{ p: 1 }}>
      <Box position={"sticky"} top={0} zIndex={5} bgcolor={"white"}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Badge badgeContent={getDifference()} max={99999} color="error">
            <Typography variant="h5" flexGrow={1}>
              Split Details
            </Typography>
          </Badge>

          <Badge badgeContent={splitDetails.length} color="primary">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Add />}
              size="small"
              onClick={() =>
                setReviewForm((prev) => ({
                  ...prev,
                  splitDetails: [...splitDetails, SPLIT],
                }))
              }
              disabled={!vendor}
            >
              Add
            </Button>
          </Badge>
        </Toolbar>
      </Box>

      <List>
        {splitDetails.map((split) => (
          <Box
            key={split["id"]}
            component={ListItem}
            disableGutters
            divider
            display={"grid"}
            gridTemplateColumns={"repeat(3, 1fr)"}
            gap={GUTTER}
            alignItems={"baseline"}
            secondaryAction={
              <IconButton
                color="error"
                onClick={() => removeSplitDetails(split["id"])}
              >
                <Delete />
              </IconButton>
            }
          >
            <Autocomplete
              size="small"
              value={getValue(split)}
              options={accounts}
              sx={{ gridColumn: "span 3" }}
              getOptionLabel={(option) => option.accountDescription}
              onChange={(_, v) => handleOnChange(v, split["id"])}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Account"
                  error={Boolean(validLineItem("AccountCode", split))}
                  helperText={validLineItem("AccountCode", split)}
                />
              )}
              renderOption={RenderOption}
              loading={accounts.length === 0}
            />

            <Select
              name="storeID"
              value={split["storeID"]}
              onChange={(e) => handleInputChange(split["id"], e)}
              disabled={profitCenterList.length === 0}
              size="small"
              displayEmpty
              renderValue={renderSelectValue}
            >
              {profitCenterList.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>

            <TextField
              size="small"
              name="Description"
              placeholder="Description"
              value={split["Description"]}
              onChange={(e) => handleInputChange(split["id"], e)}
            />

            <NumericFormat
              customInput={TextField}
              size="small"
              name="Amount"
              value={split["Amount"]}
              placeholder="Amount"
              onChange={(e) => handleInputChange(split["id"], e)}
              error={Boolean(validLineItem("Amount", split))}
              helperText={validLineItem("Amount", split)}
            />
          </Box>
        ))}
      </List>
    </Paper>
  );
};

const RenderOption = (props, option) => (
  <Box {...props} key={option.id}>
    <Typography variant="body1" flexGrow={1}>
      {option.accountDescription}
    </Typography>
    <Typography variant="body2">{option.accountTypeName}</Typography>
  </Box>
);

const renderSelectValue = (value) => {
  if (!value) {
    return "PC";
  }
  return value;
};
