import { BooksDateScheme } from "./customScheme";
import { billEntrySchema, splitDetailSchema } from "./schema";

export function validateProperty(property) {
  try {
    billEntrySchema.validateSyncAt("property", { property });
  } catch (error) {
    return error.message;
  }
}

export function validateVendor(vendor) {
  try {
    billEntrySchema.validateSyncAt("vendor", { vendor });
  } catch (error) {
    return error.message;
  }
}

export function validateContract(contract) {
  try {
    billEntrySchema.validateSyncAt("contract", { contract });
  } catch (error) {
    return error.message;
  }
}

// export function validatePaymentType(payment_type) {
//   try {
//     billEntrySchema.validateSyncAt("payment_type", { payment_type });
//   } catch (error) {
//     return error.message;
//   }
// }

export function validateInvoiceNumber(invoice_number) {
  try {
    billEntrySchema.validateSyncAt("invoice_number", { invoice_number });
  } catch (error) {
    return error.message;
  }
}

export function validateInvoiceAmount(invoice_amount) {
  try {
    billEntrySchema.validateSyncAt("invoice_amount", { invoice_amount });
  } catch (error) {
    return error.message;
  }
}

export function validateInvoiceDate(invoice_date) {
  try {
    billEntrySchema.validateSyncAt("invoice_date", { invoice_date });
  } catch (error) {
    return error.message;
  }
}

export function validatePaymentDueDate(payment_due_date, invoice_date) {
  try {
    billEntrySchema.validateSyncAt("payment_due_date", {
      payment_due_date,
      invoice_date,
    });
  } catch (error) {
    return error.message;
  }
}

export function validateBooksDate(books_date) {
  try {
    BooksDateScheme.validateSyncAt("books_date", { books_date });
  } catch (error) {
    return error.message;
  }
}

export function validLineItem(key, lineItem) {
  try {
    splitDetailSchema.validateSyncAt(key, { [key]: lineItem[key] });
  } catch (error) {
    return error.message;
  }
}
