import React, { useState } from "react";
import { Table, Button } from "antd";
import { Stack, IconButton, Chip, Typography, Box } from "@mui/material";
import {
  DownloadOutlined,
  ArrowDownward,
  ArrowUpward,
} from "@mui/icons-material";
import { lighten } from "@mui/material/styles";
import moment from "moment";
import { EmailOutlined } from "@mui/icons-material";

const data = [
  {
    _id: "670e6022697dadb10ce17639",
    email_table_id: "670e6018697dadb10ce17637",
    file_table_id: "670e601a697dadb10ce17638",
    source_filename: "PEST-END 985873-1.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241015T122910Z_PEST-END_985873-1.pdf",
    bill_status: 0,
    timestamp: "2024-10-15 12:29:22",
    invoice_number: "985873-1",
    invoice_date: "12/28/2023",
    invoice_amount: "134.00",
    vendor_name: "Pest End",
    receivedDateTime: "2024-10-15 12:29:10",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "Fw: ",
  },
  {
    _id: "670e6023934c50d660e17638",
    email_table_id: "670e6018697dadb10ce17637",
    file_table_id: "670e6019934c50d660e17637",
    source_filename: "peaples linen 140082105.PDF",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241015T122910Z_peaples_linen_140082105.PDF",
    bill_status: 0,
    timestamp: "2024-10-15 12:29:23",
    invoice_number: "140082105",
    invoice_date: "11/14/2023",
    invoice_amount: "226.79",
    vendor_name: "People's Linen Service",
    receivedDateTime: "2024-10-15 12:29:10",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "Fw: ",
  },
  {
    _id: "670e60243a443735bbe1763b",
    email_table_id: "670e6018697dadb10ce17637",
    file_table_id: "670e601a3a443735bbe1763a",
    source_filename: "Hotel Planner 7590889275200.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241015T122910Z_Hotel_Planner_7590889275200.pdf",
    bill_status: 0,
    timestamp: "2024-10-15 12:29:24",
    invoice_number: "7590889275200",
    invoice_date: "November 11, 2023",
    invoice_amount: "$591.50 USD",
    vendor_name: "Lexyl Travel Technologies",
    receivedDateTime: "2024-10-15 12:29:10",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "Fw: ",
  },
  {
    _id: "670e60251fb7449a6ee17638",
    email_table_id: "670e6018697dadb10ce17637",
    file_table_id: "670e601a1fb7449a6ee17637",
    source_filename: "Great America 35294532.PDF",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241015T122910Z_Great_America_35294532.PDF",
    bill_status: 0,
    timestamp: "2024-10-15 12:29:25",
    invoice_number: "35294532",
    invoice_date: "11/13/2023",
    invoice_amount: "39.50",
    vendor_name: "GreatAmerica Financial Svcs.",
    receivedDateTime: "2024-10-15 12:29:10",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "Fw: ",
  },
  {
    _id: "670e602b697dadb10ce1763c",
    email_table_id: "670e6018697dadb10ce17637",
    file_table_id: "670e6023697dadb10ce1763b",
    source_filename: "Libert Utilities 02-12-24.PDF",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241015T122910Z_Libert_Utilities_02-12-24.PDF",
    bill_status: 0,
    timestamp: "2024-10-15 12:29:31",
    invoice_number: "200003573892",
    invoice_date: "19-JAN-2024",
    invoice_amount: "$223.71",
    vendor_name: "Liberty Utilities",
    receivedDateTime: "2024-10-15 12:29:10",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "Fw: ",
  },
  {
    _id: "670e602d3a443735bbe1763e",
    email_table_id: "670e6018697dadb10ce17637",
    file_table_id: "670e60243a443735bbe1763d",
    source_filename: "STANLEY ELEVATOR 02-01-24.PDF",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241015T122910Z_STANLEY_ELEVATOR_02-01-24.PDF",
    bill_status: 0,
    timestamp: "2024-10-15 12:29:33",
    invoice_number: "SRV000478543",
    invoice_date: "2/1/2024",
    invoice_amount: "$462.00",
    vendor_name: "Stanley Elevator Company, Inc.",
    receivedDateTime: "2024-10-15 12:29:10",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "Fw: ",
  },
  {
    _id: "670e602e934c50d660e1763b",
    email_table_id: "670e6018697dadb10ce17637",
    file_table_id: "670e6024934c50d660e1763a",
    source_filename: "Great America 35294532.PDF",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241015T122910Z_Great_America_35294532.PDF",
    bill_status: 0,
    timestamp: "2024-10-15 12:29:34",
    invoice_number: "35294532",
    invoice_date: "11/13/2023",
    invoice_amount: "39.50",
    vendor_name: "GreatAmerica Financial Svcs.",
    receivedDateTime: "2024-10-15 12:29:10",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "Fw: ",
  },
  {
    _id: "670e627d9679d020e75faa58",
    email_table_id: "670e62269679d020e75faa56",
    file_table_id: "670e62299679d020e75faa57",
    source_filename: "Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Residence Inn - Hamden, CT/20241015T123755Z_Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    bill_status: 0,
    timestamp: "2024-10-15 12:39:25",
    invoice_number: "9219132229",
    invoice_date: "10/02/2023",
    invoice_amount: "220.85",
    vendor_name: "HD Supply Facilities Maintenance, Ltd.",
    receivedDateTime: "2024-10-15 12:37:55",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "670e627d9679d020e75faa5a",
    email_table_id: "670e62269679d020e75faa56",
    file_table_id: "670e62299679d020e75faa57",
    source_filename: "Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Residence Inn - Hamden, CT/20241015T123755Z_Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    bill_status: 0,
    timestamp: "2024-10-15 12:39:25",
    invoice_number: "9219132230",
    invoice_date: "10/02/2023",
    invoice_amount: "75.61",
    vendor_name: "HD Supply Facilities Maintenance, Ltd.",
    receivedDateTime: "2024-10-15 12:37:55",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "670e627d9679d020e75faa5c",
    email_table_id: "670e62269679d020e75faa56",
    file_table_id: "670e62299679d020e75faa57",
    source_filename: "Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Residence Inn - Hamden, CT/20241015T123755Z_Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    bill_status: 0,
    timestamp: "2024-10-15 12:39:25",
    invoice_number: "9219176652",
    invoice_date: "10/03/2023",
    invoice_amount: "691.40",
    vendor_name: "HD Supply Facilities Maintenance, Ltd.",
    receivedDateTime: "2024-10-15 12:37:55",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "670e627d9679d020e75faa5e",
    email_table_id: "670e62269679d020e75faa56",
    file_table_id: "670e62299679d020e75faa57",
    source_filename: "Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Residence Inn - Hamden, CT/20241015T123755Z_Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    bill_status: 0,
    timestamp: "2024-10-15 12:39:25",
    invoice_number: "9219176651",
    invoice_date: "10/03/2023",
    invoice_amount: "901.34",
    vendor_name: "HD Supply Facilities Maintenance, Ltd.",
    receivedDateTime: "2024-10-15 12:37:55",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "670e627d9679d020e75faa60",
    email_table_id: "670e62269679d020e75faa56",
    file_table_id: "670e62299679d020e75faa57",
    source_filename: "Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Residence Inn - Hamden, CT/20241015T123755Z_Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    bill_status: 0,
    timestamp: "2024-10-15 12:39:25",
    invoice_number: "9219239759",
    invoice_date: "10/04/2023",
    invoice_amount: "81.53",
    vendor_name: "HD Supply Facilities Maintenance, Ltd.",
    receivedDateTime: "2024-10-15 12:37:55",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "670e627d9679d020e75faa62",
    email_table_id: "670e62269679d020e75faa56",
    file_table_id: "670e62299679d020e75faa57",
    source_filename: "Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Residence Inn - Hamden, CT/20241015T123755Z_Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    bill_status: 0,
    timestamp: "2024-10-15 12:39:25",
    invoice_number: "9219266715",
    invoice_date: "10/05/2023",
    invoice_amount: "141.19",
    vendor_name: "HD Supply Facilities Maintenance, Ltd.",
    receivedDateTime: "2024-10-15 12:37:55",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "670e627d9679d020e75faa64",
    email_table_id: "670e62269679d020e75faa56",
    file_table_id: "670e62299679d020e75faa57",
    source_filename: "Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Residence Inn - Hamden, CT/20241015T123755Z_Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    bill_status: 0,
    timestamp: "2024-10-15 12:39:25",
    invoice_number: "190036914",
    invoice_date: "Dec 15, 2023",
    invoice_amount: "2,085.44",
    vendor_name: "COMCAST BUSINESS",
    receivedDateTime: "2024-10-15 12:37:55",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "670e627d9679d020e75faa66",
    email_table_id: "670e62269679d020e75faa56",
    file_table_id: "670e62299679d020e75faa57",
    source_filename: "Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Residence Inn - Hamden, CT/20241015T123755Z_Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    bill_status: -2,
    timestamp: "2024-10-15 12:39:25",
    error_description: "Some pages are missing extraction",
    receivedDateTime: "2024-10-15 12:37:55",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "670e6355ee615b48b7025189",
    email_table_id: "670e62269679d020e75faa56",
    file_table_id: "670e62299679d020e75faa57",
    source_filename: "Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Residence Inn - Hamden, CT/20241015T123755Z_Scanned_from_a_ResidenceInn01-04-2024-150835.pdf",
    bill_status: 0,
    timestamp: "2024-10-15 12:43:01",
    invoice_number: "9219239758",
    invoice_date: "10/04/2023",
    invoice_amount: "50.80",
    vendor_name: "HD Supply Facilities Maintenance, Ltd.",
    receivedDateTime: "2024-10-15 12:37:55",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "6716200317e1b8abc26b0192",
    email_table_id: "67161ff817e1b8abc26b0190",
    file_table_id: "67161ff917e1b8abc26b0191",
    source_filename: "FF Invoices_001.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging10/Ramada Beaver Falls/20241021T093340Z_FF_Invoices_001.pdf",
    bill_status: 0,
    timestamp: "2024-10-21 09:33:55",
    invoice_number: "773465",
    invoice_date: "08/01/24",
    invoice_amount: "$761.00",
    vendor_name: "LandCare USA LLC",
    receivedDateTime: "2024-10-21 09:33:40",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "6716200317e1b8abc26b0194",
    email_table_id: "67161ff817e1b8abc26b0190",
    file_table_id: "67161ff917e1b8abc26b0191",
    source_filename: "FF Invoices_001.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging10/Ramada Beaver Falls/20241021T093340Z_FF_Invoices_001.pdf",
    bill_status: 0,
    timestamp: "2024-10-21 09:33:55",
    invoice_number: "770058",
    invoice_date: "08/01/24",
    invoice_amount: "$511.67",
    vendor_name: "LandCare USA LLC",
    receivedDateTime: "2024-10-21 09:33:40",
    from: "manikanta.n@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "6729cd4bdfd10022197d17c1",
    email_table_id: "6729cd46ffe281a9147d17c0",
    file_table_id: "6729cd47dfd10022197d17c0",
    source_filename: "Hotel Statistics (12) (1).pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241105T074611Z_Hotel_Statistics_(12)_(1).pdf",
    bill_status: -2,
    timestamp: "2024-11-05 07:46:19",
    error_description: "Required Entities not found",
    receivedDateTime: "2024-11-05 07:46:11",
    from: "ravindra.bodavula@nimbleaccounting.com",
    type: "mail",
    subject: "Mail",
  },
  {
    _id: "672a22c1ffe281a9147d17c3",
    email_table_id: "672a22baffe281a9147d17c1",
    file_table_id: "672a22bbffe281a9147d17c2",
    source_filename: "Guest supply.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241105T135047Z_Guest_supply.pdf",
    bill_status: 0,
    timestamp: "2024-11-05 13:50:57",
    invoice_number: "16669301",
    invoice_date: "01/22/24",
    invoice_amount: "$232.10",
    vendor_name: "Guest Supply",
    receivedDateTime: "2024-11-05 13:50:47",
    from: "ravindra.bodavula@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "672a2439ffe281a9147d17c7",
    email_table_id: "672a242effe281a9147d17c5",
    file_table_id: "672a242fffe281a9147d17c6",
    source_filename: "HD Supply.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241105T135700Z_HD_Supply.pdf",
    bill_status: 0,
    timestamp: "2024-11-05 13:57:13",
    invoice_number: "9219384581",
    invoice_date: "10/09/2023",
    invoice_amount: "195.63",
    vendor_name: "HD Supply Facilities Maintenance, Ltd.",
    receivedDateTime: "2024-11-05 13:57:00",
    from: "ravindra.bodavula@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "672a2485ffe281a9147d17cb",
    email_table_id: "672a2471ffe281a9147d17c9",
    file_table_id: "672a2471ffe281a9147d17ca",
    source_filename: "Sysco.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241105T135807Z_Sysco.pdf",
    bill_status: 0,
    timestamp: "2024-11-05 13:58:29",
    invoice_number: "961151",
    invoice_date: "1/01/24",
    invoice_amount: "1437.18",
    vendor_name: "Sysco Northern New England",
    receivedDateTime: "2024-11-05 13:58:07",
    from: "ravindra.bodavula@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "672a24bfffe281a9147d17cf",
    email_table_id: "672a24b4ffe281a9147d17cd",
    file_table_id: "672a24b5ffe281a9147d17ce",
    source_filename: "Waste management.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241105T135914Z_Waste_management.pdf",
    bill_status: 0,
    timestamp: "2024-11-05 13:59:27",
    invoice_number: "4098113-0459-4",
    invoice_date: "12/26/2023",
    invoice_amount: "5,504.64",
    vendor_name: "WASTE MANAGEMENT OF NEW HAMPSHIRE INC.",
    receivedDateTime: "2024-11-05 13:59:14",
    from: "ravindra.bodavula@nimbleaccounting.com",
    type: "mail",
    subject: "",
  },
  {
    _id: "672a24ffffe281a9147d17d3",
    email_table_id: "672a24f2ffe281a9147d17d1",
    file_table_id: "672a24f2ffe281a9147d17d2",
    source_filename: "Spectrum.pdf",
    s3_key:
      "NimbleIO_Staging/bill_automation/staging9/Best Western - West Lebanon, NH/20241105T140016Z_Spectrum.pdf",
    bill_status: 0,
    timestamp: "2024-11-05 14:00:31",
    invoice_number: "174272301120723",
    invoice_date: "12/07/23",
    invoice_amount: "1602.60",
    vendor_name: "Spectrum Business Voice",
    receivedDateTime: "2024-11-05 14:00:16",
    from: "ravindra.bodavula@nimbleaccounting.com",
    type: "mail",
    subject: "Spectrum",
  },
];

const statusMap = {
  0: { name: "To be posted", color: "#2ca01cfe" },
  2: { name: "Approval", color: " #00bfbf" },
  "-2": { name: "Extraction failed", color: "#808000fe" },
  "-3": { name: "Invalid file", color: "#d9001bfe" },
};
const ACTION_BUTTON_COLOR = "#003dfe";
const FROM_MAIL_COLOR = "#67E0BC";

export default function GroupedTable() {
  return (
    <Box height={"100%"}>
      <Table
        virtual
        scroll={{ y: window.innerHeight - 40 }}
        size="small"
        columns={[
          {
            title: "File Details",
            dataIndex: "source_filename",
            onCell: (data, index) => {
              return {
                colSpan: index === 1 ? 5 : 1,
              };
            },
          },
          {
            title: "Payee Name",
            dataIndex: "vendor_name",
          },
          {
            title: "Bill Number",
            dataIndex: "invoice_number",
          },
        ]}
        dataSource={data}
        bordered
        pagination={false}
      />
    </Box>
  );
}
const sharedOnCell = (_, index) => {
  if (index === 1) {
    return {
      colSpan: 0,
    };
  }
  return {};
};
