import {
  ArrowDownward,
  ArrowUpward,
  Clear,
  Download,
  EmailOutlined,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  lighten,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import moment from "moment";
import { Table } from "rsuite";
import { useMemo, useState } from "react";
import { statusMap } from "../InboxPage/InboxPage";
import ReviewPage from "../../Components/ReviewPage/ReviewPage";
import { enqueueSnackbar } from "notistack";

const { Column, HeaderCell, Cell } = Table;

const FROM_MAIL_COLOR = "#67E0BC";
const ACTION_BUTTON_COLOR = "#003dfe";

const GroupedTable = ({ bills }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isDownloading, setIsDownloading] = useState();
  const [selectedBill, setSelectedBill] = useState();

  async function downloadFile(file_table_id, s3_key) {
    setIsDownloading(file_table_id);

    let s3_uri = `https://nimbleocrbills.s3.us-east-1.amazonaws.com/${s3_key}`;
    let fileName = s3_uri.split("/").pop();

    try {
      const response = await fetch(s3_uri);
      const blob = await response.blob();
      const link = document.createElement("a");
      const urlObject = URL.createObjectURL(blob);

      link.href = urlObject;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error("Download failed:", error);
    }
    setIsDownloading();
  }

  return (
    <>
      <Table
        bordered
        cellBordered
        virtualized
        fillHeight
        data={prepareData(bills)}
        isTree
        rowKey="_id"
        shouldUpdateScroll={false}
        rowHeight={(rowData) => (rowData?.treeHeader ? 115 : 46)}
        rowClassName={(rowData) => !rowData?.treeHeader && "expanded-rows"}
        expandedRowKeys={expandedRowKeys}
        renderTreeToggle={() => false}
        translate3d
      >
        <Column
          flexGrow={2}
          verticalAlign="middle"
          colSpan={7}
          rowSpan={(rowData) => rowData["fileNameSpan"]}
        >
          <HeaderCell>File Details</HeaderCell>
          <Cell
            children={(rowData, rowIndex) => {
              if (rowData.treeHeader) {
                let billStats = statusCounts(rowData["children"]);
                let expanded = expandedRowKeys.includes(rowData["_id"]);
                return (
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"row"} gap={4} alignItems={"center"}>
                      <Box
                        bgcolor={"#effdd9"}
                        borderRadius={"50%"}
                        height={38}
                        width={38}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <EmailOutlined
                          sx={{ color: FROM_MAIL_COLOR, fontSize: 28 }}
                        />
                      </Box>

                      <Stack gap={0.5}>
                        <InfoRow
                          label="From"
                          value={rowData["from"]}
                          valueProps={{
                            fontSize: 18,
                            color: "#67E0BC",
                          }}
                        />

                        <InfoRow
                          label="Subject"
                          value={rowData["subject"]}
                          valueProps={{ fontSize: 14 }}
                        />

                        <InfoRow
                          label="Received on"
                          value={moment(rowData["receivedDateTime"]).format(
                            "MMMM DD[,] YYYY hh:mmA"
                          )}
                          valueProps={{ fontSize: 14 }}
                        />
                      </Stack>
                    </Stack>

                    {!expanded && (
                      <Stack direction={"row"} gap={2}>
                        {Object.entries(billStats).map(
                          ([status, stats], index) => (
                            <Chip
                              key={index}
                              size="small"
                              label={`${status} (${stats["count"]})`}
                              sx={{
                                bgcolor: lighten(stats["color"], 0.8),
                                color: stats["color"],
                              }}
                            />
                          )
                        )}
                      </Stack>
                    )}
                  </Stack>
                );
              } else {
                return rowData["source_filename"];
              }
            }}
          />
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Payee Name</HeaderCell>
          <Cell
            children={(rowData) =>
              rowData.treeHeader ? null : rowData["vendor_name"] || "-"
            }
          />
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Bill Number</HeaderCell>
          <Cell
            children={(rowData) =>
              rowData.treeHeader ? null : rowData["invoice_number"] || "-"
            }
          />
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Bill Date</HeaderCell>
          <Cell
            children={(rowData) =>
              rowData.treeHeader ? null : rowData["invoice_date"] || "-"
            }
          />
        </Column>

        <Column>
          <HeaderCell>Amount</HeaderCell>
          <Cell
            children={(rowData) =>
              rowData.treeHeader ? null : rowData["invoice_amount"] || "-"
            }
          />
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Status</HeaderCell>
          <Cell
            children={(rowData) => {
              if (rowData.treeHeader) {
                return null;
              }

              let billStat = statusMap[rowData["bill_status"]];

              let status = billStat["name"];
              let color = billStat["color"];

              return (
                <Chip
                  label={status}
                  size="small"
                  sx={{
                    bgcolor: lighten(color, 0.8),
                    color: color,
                  }}
                />
              );
            }}
          />
        </Column>

        <Column>
          <HeaderCell>Action</HeaderCell>
          <Cell
            children={(rowData) => {
              if (rowData.treeHeader) {
                return null;
              }
              return (
                <Button
                  disableRipple
                  size="small"
                  sx={{
                    borderRadius: 1,
                    bgcolor: lighten(ACTION_BUTTON_COLOR, 0.9),
                    color: ACTION_BUTTON_COLOR,
                    ":hover": {
                      bgcolor: lighten(ACTION_BUTTON_COLOR, 0.9),
                    },
                  }}
                  onClick={() => setSelectedBill(rowData)}
                >
                  View
                </Button>
              );
            }}
          />
        </Column>

        <Column
          width={130}
          fixed={"right"}
          rowSpan={(rowData) => rowData["fileNameSpan"]}
        >
          <HeaderCell>Download</HeaderCell>
          <Cell
            verticalAlign="middle"
            align="center"
            style={{ padding: 0 }}
            children={(rowData) => {
              let rowKey = rowData["_id"];
              let expanded = expandedRowKeys.includes(rowKey);
              return rowData.treeHeader ? (
                <Button
                  fullWidth
                  disableRipple
                  sx={{
                    borderRadius: 0,
                    height: "100%",
                    bgcolor: "#e0f9f1",
                    ":hover": {
                      bgcolor: "#e0f9f1",
                    },
                    color: "black",
                  }}
                  endIcon={expanded ? <ArrowUpward /> : <ArrowDownward />}
                  onClick={() =>
                    setExpandedRowKeys((prevKeys) =>
                      expanded
                        ? prevKeys.filter((key) => key !== rowKey)
                        : [...prevKeys, rowKey]
                    )
                  }
                >
                  {`Files (${getFileCount(rowData.children)})`}
                </Button>
              ) : (
                <IconButton
                  disabled={isDownloading === rowData["file_table_id"]}
                  disableRipple
                  size="small"
                  sx={{
                    bgcolor: "#4cd3aa",
                    borderRadius: 1,
                    height: 20,
                    width: 20,
                    ":hover": {
                      bgcolor: "#4cd3aa",
                    },
                  }}
                  onClick={() =>
                    downloadFile(rowData["file_table_id"], rowData["s3_key"])
                  }
                >
                  <Download sx={{ fontSize: 18, color: "white" }} />
                </IconButton>
              );
            }}
          />
        </Column>
      </Table>

      <Dialog open={Boolean(selectedBill)} fullScreen>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Stack direction={"row"} gap={1} alignItems={"center"} flexGrow={1}>
              <Typography variant="h5">Review</Typography>
              <Chip
                variant="outlined"
                size="small"
                label={selectedBill?.["source_filename"]}
                sx={{ color: "inherit" }}
                clickable
                onClick={() => {
                  navigator.clipboard.writeText(
                    selectedBill?.["source_filename"]
                  );
                  enqueueSnackbar("Text Copied", { variant: "info" });
                }}
              />
            </Stack>

            <IconButton color="inherit" onClick={() => setSelectedBill()}>
              <Clear />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ p: 1 }}>
          {useMemo(() => {
            return selectedBill && <ReviewPage bill={selectedBill} />;
          }, [selectedBill])}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GroupedTable;

/**
 * EmailDetail component renders an email detail with a label and value.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.value - The value to display.
 * @param {string} props.label - The label to display.
 * @param {import("@mui/material").TypographyProps} props.valueProps - Props for customizing the value's typography.
 * @returns {JSX.Element} The rendered EmailDetail component.
 */
function InfoRow({ value, label, valueProps }) {
  return (
    <Stack
      direction={"row"}
      gap={1}
      alignItems={"center"}
      fontFamily={"inherit"}
    >
      <Typography width={96} fontSize={12}>
        {label}
      </Typography>
      <Typography fontWeight={500} {...valueProps}>
        {value}
      </Typography>
    </Stack>
  );
}

function getFileCount(bills) {
  return [...new Set(bills.map((i) => i["file_table_id"]))].length;
}

function statusCounts(bills) {
  return bills.reduce((acc, bill) => {
    const status = bill.bill_status;
    const statusInfo = statusMap[status] || {
      name: "UNKNOWN",
      color: "#808080",
    };

    if (!acc[statusInfo.name]) {
      acc[statusInfo.name] = { count: 0, color: statusInfo.color };
    }

    acc[statusInfo.name].count += 1;
    return acc;
  }, {});
}
function prepareData(data) {
  const countMap = data.reduce((acc, obj) => {
    acc[obj.file_table_id] = (acc[obj.file_table_id] || 0) + 1;
    return acc;
  }, {});

  const updatedData = data.map((obj, index, arr) => {
    if (
      arr.findIndex((item) => item.file_table_id === obj.file_table_id) ===
      index
    ) {
      return { ...obj, fileNameSpan: countMap[obj.file_table_id] };
    }
    return obj;
  });

  const finalData = updatedData.reduce((acc, item) => {
    let group = acc.find((g) => g._id === item.email_table_id);
    if (group) {
      group.children.push(item);
    } else {
      acc.push({
        _id: item.email_table_id,
        treeHeader: true,
        fileNameSpan: 1,

        receivedDateTime: item["receivedDateTime"],
        from: item["from"],
        type: item["type"],
        subject: item["subject"],

        source_filename: "Email",
        children: [item],
      });
    }
    return acc;
  }, []);

  return finalData;
}
