import { ArrowForward } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Fab,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { InboxPage } from "../InboxPage/InboxPage";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../helpers/variables";
import { enqueueSnackbar, SnackbarProvider } from "notistack";

export const Home = () => {
  const navigate = useNavigate();

  const [client, setClient] = useState(null);
  const [corporation, setCorporation] = useState(null);
  const [field, setField] = useState({
    authID: "",
    clientSecret: "",
  });

  const [clientsList, setClientList] = useState([]);
  const [corporationList, setCorporationList] = useState([]);

  useEffect(() => {
    async function fetchClientList() {
      let res = await fetch(`${BACKEND_URL}/client_list`);
      let data = await res.json();
      setClientList(data);
    }

    fetchClientList();
  }, []);

  useEffect(() => {
    setCorporation(null);
    setCorporationList([]);

    async function fetchCorporationList() {
      if (client) {
        let url = `${BACKEND_URL}/corporation_list?client_url=${client["url"]}`;
        let res = await fetch(url);
        let data = await res.json();
        setCorporationList(data);
      }
    }

    fetchCorporationList();
  }, [client]);

  function updateField(e) {
    let key = e.target.name;
    let value = e.target.value;
    setField((prev) => ({ ...prev, [key]: value }));
  }

  const handleButtonClick = async () => {
    window.open(
      `https://snap.nimbleproperty.net/inbox?id=${field["authID"]}&client_url=${client["url"]}&corporation_id=${corporation["corporationID"]}`,
      "_blank"
    );
  };

  return (
    <SnackbarProvider>
      <Stack height={"100vh"} justifyContent={"center"} alignItems={"center"}>
        <Stack
          gap={3}
          width={"25%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <TextField
            size="small"
            fullWidth
            value={field["authID"]}
            name="authID"
            label="Auth ID"
            onChange={updateField}
          />
          <Autocomplete
            options={clientsList}
            value={client}
            size="small"
            fullWidth
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            onChange={(_, v) => setClient(v)}
            renderInput={(params) => <TextField {...params} label="Clients" />}
          />
          <Autocomplete
            options={corporationList}
            value={corporation}
            size="small"
            fullWidth
            getOptionLabel={(option) => option.bookkeepingDirectory}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            onChange={(_, v) => setCorporation(v)}
            renderInput={(params) => (
              <TextField {...params} label="Properties" />
            )}
          />
          <Box>
            <Fab
              color="primary"
              size="small"
              disabled={
                !corporation ||
                field["authID"].trim() === ""
              }
              onClick={
                handleButtonClick
                // () => setOpenInbox(true)
                // navigate(`/inbox/${client["url"]}/${corporation["corporationID"]}`)
              }
            >
              <ArrowForward />
            </Fab>
          </Box>
        </Stack>
      </Stack>
    </SnackbarProvider>
  );
};
