import { createBrowserRouter } from "react-router-dom";
import { Home } from "./Home/Home";
import  InboxPage  from "./InboxPage/InboxPage";
import { OAUTH_URL } from "../helpers/variables";
// import GroupedTable from "./Test/Test";
import GroupedTable from "./Test/ANTDTable";

async function AuthUser(auth_id) {
  try {
    let res = await fetch(`${OAUTH_URL}/login?auth_id=${auth_id}`);
    let data = await res.json();
    if (!res.ok) {
      throw new Response(`Authentication failed, ${res.statusText}`, {
        status: res.status,
      });
    }
    return data;
  } catch (err) {
    throw new Response(`Authentication failed, ${err.message}`, {
      status: 500,
    });
  }
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path:"test",
    element:<GroupedTable />
  },
  {
    path: "/inbox",
    loader: async ({ request }) => {
      const url = new URL(request.url);
      const authID = url.searchParams.get("id");
      const client_url = url.searchParams.get("client_url");
      const corporation_id = url.searchParams.get("corporation_id");

      if (!client_url || !corporation_id) {
        throw new Response("Missing required parameters", { status: 400 });
      }

      let payload = await AuthUser(authID);
      return {
        ...payload,
        client_url,
        corporation_id,
      };
    },
    element: <InboxPage />,
  },
]);

export default router;
