import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import InboxHeader from "./Header/Header";
import { BACKEND_URL } from "../../helpers/variables";
import moment from "moment";
import { useLoaderData } from "react-router-dom";
import Test from "../Test/Test";
import { SnackbarProvider } from "notistack";

export const statusMap = {
  0: { name: "To be posted", color: "#2ca01cfe" },
  2: { name: "Approval", color: "#00bfbf" },
  "-2": { name: "Extraction failed", color: "#808000fe" },
  "-3": { name: "Invalid file", color: "#d9001bfe" },
};

const InboxPage = () => {
  let { client_url, corporation_id } = useLoaderData();
  /**
   * @type {[{
   *   dateRange: Array<Date>,
   *   transaction: string,
   *   status: string
   * } , React.Dispatch<React.SetStateAction<number>]}
   */
  const [filters, setFilters] = useState({
    dateRange: null,
    transaction: "Today",
    status: "all",
  });

  const [bills, setBills] = useState([]);
  const [sourceBills, setSourceBills] = useState([]);

  const updateFilter = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (filters.dateRange) {
      getBills();
    }
  }, [filters]);

  async function getBills() {
    setBills([]);
    let { dateRange, transaction, status } = filters;
    let [startDate, endDate] = dateRange;

    let format = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
    startDate = moment(startDate).format(format);
    endDate = moment(endDate).format(format);

    let res = await fetch(`${BACKEND_URL}/get_bills`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status,
        startDate,
        endDate,
        client_url,
        corporation_id,
      }),
    });

    let data = await res.json();
    setBills(data);
    setSourceBills(data);
  }

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Stack height={"100%"}>
        <InboxHeader
          bills={sourceBills}
          setBills={setBills}
          filters={filters}
          updateFilter={updateFilter}
        />
        <Box height={"calc(100% - 64px)"}>
          <Test bills={bills} />
        </Box>
      </Stack>
    </SnackbarProvider>
  );
};

export default InboxPage;
